// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: Print
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

@import
  'vendor/fontello-variables';

@import
  'utility/variables',
  'utility/mixins';


.printonly {
  display: block!important;
}

.blurred {
  filter: none;
  overflow: visible;
  &::after {
    display: none;
  }
}

*,
*::before,
*::after {
  //color: $color-black !important;
  box-shadow: none !important;
  text-shadow: none !important;
  transition: none !important;
  animation: none !important;
  //transform: none !important;
  //opacity: 1 !important;
}

.p-body {
  width: 1460px;

  &.noscroll {
    overflow: visible;
  }
}

header {
  position: static !important;
}

footer .c-signup {
  display: none!important;
}

.footer {
  &__wrap {
    display: block!important;
    padding: 1rem 2rem;

    .btn-text {
      display: none;
    }
  }
  &__nav {
    display: none!important;
  }

  &-siteinfo {
    margin: 0;
    color: $color-black;
    font-size: 1rem;

    &__item {
      display: none;
      &.copyright {
        display: block;
      }
    }
  }
}


// see https://stackoverflow.com/questions/39516885/workaround-for-chrome-53-printing-table-header-twice-on-2nd-and-later-pages
thead {
  display: table-row-group;
}

// prevent page breaks in components
.c-spotlight,
.c-gallery,
.c-featurebar,
.c-productrelated {
  page-break-inside: avoid;
}


// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: Print view of specific page layouts or sections
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

.printview {

  // ------------------------------------------------
  // Product Comparison tab
  &--comparison {
    @page {
      size: landscape;
    }

    .p-main {
      display: none;
    }

    .c-productcompare {
      position: relative;
      top: 0;
      height: auto;
      padding: 2rem 0;

      &__buttons,
      .btn-icon {
        display: none;
      }
      &__pulltab {
        position: static;
      }
      &__wrap {
        overflow: visible !important;
      }
      &__content {
        display: block;
        padding: 2rem;
        box-shadow: none;
      }
      .c-tabs {
        &__index,
        &__hilite,
        &__select {
          display: none;
        }
        &__content > div {
          position: relative;
          opacity: 1;
          height: auto;
          overflow: visible;
          padding: 1rem 0;

          + div {
            page-break-before: always;
          }
        }
      }
      &__tools {
        margin-top: 0;
      }
    }
  }
}
