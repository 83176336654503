// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: Variables
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

// Colors
// --------------------

$color-black:     #000;
$color-grey-dk:   #999;
$color-grey-dk2:  #666;
$color-grey-dk3:  #1c1c1c;
$color-grey-dk4:  #242424;
$color-grey-dk5:  #333;
$color-grey-mid:  #ccc;
$color-grey-mid2: #ececec;
$color-grey-lt:   #f5f5f5;
$color-red:       #c00;
$color-red-lt:    #fe0000;
$color-red-op65:  rgba(204, 0, 0, 0.65);
$color-red-op75:  rgba(204, 0, 0, 0.75);
$color-white:     #fff;

$color-blue:      #7bb9bb;
$color-yellow:    #eaba34;
$color-pink:      #b561a0;
$color-green:     #a8af13;

$color-product1:  $color-blue;
$color-product2:  $color-yellow;
$color-product3:  $color-pink;
$color-product4:  $color-green;

$colors: (
  'black':     $color-black,
  'grey-dk':   $color-grey-dk,
  'grey-dk2':  $color-grey-dk2,
  'grey-dk3':  $color-grey-dk3,
  'grey-dk4':  $color-grey-dk4,
  'grey-dk5':  $color-grey-dk5,
  'grey-mid':  $color-grey-mid,
  'grey-mid2': $color-grey-mid2,
  'grey-lt':   $color-grey-lt,
  'red':       $color-red,
  'red-lt':    $color-red-lt,
  'red-op65':  $color-red-op65,
  'red-op75':  $color-red-op75,
  'white':     $color-white,
  'blue':      $color-blue,
  'yellow':    $color-yellow,
  'pink':      $color-pink,
  'green':     $color-green
);


// Layout
// --------------------

$page-min-width: 20rem;     // 320
$page-mobile-side-padding: 1rem;
$content-max-width: 100rem; // 1600
$header-univ-height: 40px;
$header-menu-height: 60px;
$header-util-width: 13.5rem;
$gutter: 3.75rem;  // 60
$gallerygutter: 0.75rem;
$gallerygutter-wider: 1.25rem;
$control-height: 3rem;

$breakpoints: (
  xs: 0,
  min: 320px,
  smp: 420px,
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1600px
);

$zindex: (
  navprimary: 11,
  navutil: 12,
  blur: 20,
  nav: 21,
  popup: 22,
  btnclose: 23
);

$signup-dialog-height: 6.25rem;


// Type
// --------------------

$fonts-sans: 'Montserrat', sans-serif;
$fonts-serif: 'Merriweather', serif;

$weight-light: 300;
$weight-norm: 400;
$weight-medium: 500;
$weight-semi: 600;
$weight-bold: 700;

$base-font-size: 16;

$icon-smaller-font-size: 0.75em;


// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: Functions
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

@function rem($px, $base: $base-font-size) {
  @return ($px / $base) * 1rem;
}
