// Variables
// --------------------------
$fontello-version: "17903782";

//:::::::::::::::::::::::::::::::::::::::::::::::
@function geticon($name) {
  @return map-get($fontello-icons, $name);
}

$fontello-icons: (
  "angle-down": "\f107",
  "angle-left": "\f104",
  "angle-right": "\f105",
  "angle-up": "\f106",
  "big-game": "\e815",
  "binoculars": "\f1e5",
  "cancel": "\e805",
  "cancel-circle": "\e804",
  "circle-empty": "\f10c",
  "close": "\e825",
  "competitive": "\e81d",
  "deer": "\e814",
  "doc-text": "\f0f6",
  "down": "\f175",
  "download": "\e80c",
  "facebook": "\f09a",
  "firearms": "\e822",
  "forward": "\e80d",
  "grid": "\e813",
  "handgun": "\e81e",
  "help": "\f128",
  "instagram": "\f16d",
  "left": "\f177",
  "list": "\e812",
  "location": "\e801",
  "mail": "\e811",
  "menu": "\f0c9",
  "more-horizontal": "\e824",
  "more-vertical": "\e823",
  "ok": "\e806",
  "ok-circle": "\e807",
  "pencil": "\e810",
  "personal-defense": "\e81b",
  "play-circle2": "\e802",
  "plus": "\e826",
  "plus-circle": "\e803",
  "predator-varmint": "\e818",
  "print": "\e808",
  "recreational": "\e81c",
  "resize-full": "\e80e",
  "resize-small": "\e80f",
  "rifle": "\e81f",
  "right": "\f178",
  "rimfire": "\e821",
  "search": "\e809",
  "shotshell": "\e820",
  "sliders": "\f1de",
  "small-game": "\e819",
  "star-empty": "\e80b",
  "suitcase": "\f0f2",
  "t-shirt": "\e800",
  "target": "\e80a",
  "trash": "\f1f8",
  "turkey": "\e81a",
  "twitter": "\f099",
  "up": "\f176",
  "upland": "\e817",
  "waterfowl": "\e816",
  "youtube-play": "\f16a"
);
