// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: Mixins
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

//self-clear floats: see http://css-tricks.com/all-about-floats/
@mixin selfclear {
  &::after {
    content: "";
    visibility: hidden;
    display: block;
    clear: both;
    height: 0;
    line-height: 0;
    font-size: 0;
  }
}


//fontawesome without <i> tags
@mixin showglyphbase($iconname, $fontsize: inherit, $fontfamily: fontello) {
  content: geticon($iconname);
  font-family: $fontfamily;
  font-size: $fontsize;
  //line-height: 1;
  vertical-align: baseline;
}

@mixin showglyph($iconname, $fontsize: inherit, $pseudoafter: true) {
  @if ($pseudoafter) {
    &::after {
      @include showglyphbase($iconname, $fontsize);
      @content;
    }
  }

  @else {
    &::before {
      @include showglyphbase($iconname, $fontsize);
      @content;
    }
  }
}

@mixin breakpoint-up($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media print, (min-width: #{map-get($breakpoints, $breakpoint)}) {
      @content;
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin breakpoint-between($breakpoint1, $breakpoint2) {
  @if map-has-key($breakpoints, $breakpoint1) {
    @if map-has-key($breakpoints, $breakpoint2) {
      @media screen and (min-width: #{map-get($breakpoints, $breakpoint1)}) and (max-width: (#{map-get($breakpoints, $breakpoint2)} - 1)) {
        @content;
      }
    }
  }
  @else {
    @warn "Unfortunately, no value could be retrieved from one of: `#{$breakpoint1}` or `#{$breakpoint2}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin btn-icon-transparentbg($color: inherit) {
  .icon {
    background-color: transparent;
    color: $color;
  }

  &:hover .icon {
    background-color: $color-red;
    color: $color-white;
  }
}

@mixin callout-hidden-arrow ($hoverleftshift: true) {

  .callout-text,
  > span {
    @include showglyph('right');
    position: relative;
    left: 0;
    @if ($hoverleftshift) {
      transition: all 300ms ease-out;
      transition-delay: 100ms;
    }

    // see also: https://www.smashingmagazine.com/2013/08/absolute-horizontal-vertical-centering-css/
    &::after {
      margin: auto auto auto 0;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(100% - 0.5em);
      opacity: 0;
      height: 1em;
      line-height: 1.1;
      transition: margin 300ms ease-out, opacity 200ms;
      transition-delay: 100ms;
    }
  }

  &:hover {
    @if ($hoverleftshift) {
      .callout-text,
      > span {
        left: -0.7em;
      }
    }
    .callout-text,
    > span {
      &::after {
        margin-left: 1em;
        opacity: 1;
      }
    }
  }
}

@mixin callout-link-arrow($color: $color-red, $hovers: true, $transition: 300ms) {
  span {
    @include showglyph('right');
    display: inline-block;
    position: relative;
    margin-left: 0;
    @if ($hovers) {
      transition: all $transition ease-out;
    }

    // see also: https://www.smashingmagazine.com/2013/08/absolute-horizontal-vertical-centering-css/
    &::after {
      //height: 1em;
      margin: auto;
      padding-left: 0.5em;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 100%;
      color: $color;
      //line-height: 1.3;
      transition: all $transition ease-out;
      @content;
    }
  }

  @if ($hovers) {
    &:hover span {
      margin-left: -0.25em;
      &::after {
        padding-left: 1.25em;
      }
    }
  }
}

// This is just horizontal centering
@mixin centered-block($maxwidth: $content-max-width) {
  max-width: none;
  width: auto;
  padding-left: $page-mobile-side-padding;
  padding-right: $page-mobile-side-padding;

  @include breakpoint-up(lg) {
    max-width: $maxwidth;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-right: 0;
    padding-left: 0;
    overflow: hidden;
  }
}

@mixin setgutter {
  padding-left: 0;
  padding-right: 0;

  @include breakpoint-up(lg) {
    padding-left: ($gutter / 4);
    padding-right: ($gutter / 4);
  }
  @include breakpoint-up(xl) {
    padding-left: ($gutter / 2);
    padding-right: ($gutter / 2);
  }
}


// Horizontal and vertical centering of absolutely positioned element
@mixin coverfit ($vertPos: center, $horizPos: center) {
  overflow: hidden;
  position: relative;
  padding: 0;

  img,
  video {
    display: block;
    width: 100%;
    height: 100%;
    padding: 0;
    position: absolute;
    object-fit: cover;
    object-position: $vertPos $horizPos;
  }

  @at-root .no-objectfit & {
    img,
    video {
      display: block;
      min-width: 100%;
      min-height: 100%;
      max-width: none;
      max-height: none;
      height: auto;
      width: auto!important;
      padding: 0;
      position: absolute;
    }
    @if ($vertPos == center and $horizPos == center) {
      img,
      video {
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
      }
    }
    @if ($vertPos == top and $horizPos == right) {
      img,
      video {
        top: 0;
        right: 0;
      }
    }
    // if we need the other positions, we'll add them.
    // right now, these are all we need.
  }
}
//alternative for centering element:
// @mixin coverfit2 {
//   min-height: 100%;
//   min-width: 100%;
//   max-width: none !important;
//   width: auto;
//   margin: auto;
//   position: absolute;
//   left: -100%;
//   right: -100%;
//   top: -100%;
//   bottom: -100%;
// }

@mixin alignedsquareblock($height) {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: $height;
  width: $height;
}

@mixin scrollbar() {
  &::-webkit-scrollbar {
    width: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: lighten($color-grey-dk2, 25%);
    background-clip: padding-box;
    border: 4px solid rgba(0,0,0,0); // this gives us a width of 4px... 4px padding all the way around + 4px thumb
    border-radius: 8px;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
}

@mixin simple-siteinfo {
  .footer-siteinfo {
    display: inline-block;
    margin: 0.5rem 0;
    font-size: 0.6rem;
    line-height: 1.2;
    color: $color-grey-dk2;

    &__item.copyright {
      display: block;

      + .footer-siteinfo__item::before {
        display: none;
      }
    }

    @content;
  }
}


// Fields (input, select)
// ----------------------------------------------
@mixin field-requiredmarker {
  content: "*";
  vertical-align: text-top;
  color: $color-grey-dk2;
  line-height: 1;
  font-weight: $weight-norm;
  font-size: 0.875rem;
}

// use this on wrapper around input or select
@mixin field-has-insidelabel($height: $control-height) {
  height: $height;
  line-height: 1.5;
  font-family: $fonts-sans;
  font-size: 0.8125rem;
  text-transform: none;

  input[type="text"],
  input[type="email"],
  input[type="file"],
  select {
    font-family: $fonts-sans;
    font-weight: $weight-medium;
    font-size: 1rem;
    line-height: normal;
  }

  label {
    position: relative;
    z-index: 2;
    color: $color-grey-dk5;
    font-size: 0.8em;
    font-weight: $weight-norm;
    letter-spacing: normal;
    white-space: nowrap;
  }
}


// Color main-nav as if they're all active
// ----------------------------------------------
@mixin nav-primary-all-active {
  .nav-primary__title {
    color: $color-white;
  }
}

// Display "new" marker
@mixin new-marker {
  position: relative;

  &::before {
    content: "NEW";
    display: block;
    padding: 0.6em 1.1em;
    position: absolute;
    top: 1.5em;
    left: -1px;
    z-index: 1;
    background-color: $color-red;
    border: 1px solid $color-white;
    color: $color-white;
    line-height: 1;
    font-size: 0.75em;
    font-weight: $weight-semi;
    pointer-events: none;
  }
}


// Side-by-side image and caption (equal-sized)
// ----------------------------------------------
@mixin photocard ($minheight: 0, $cover: true) {
  @include flexrow(auto, center, stretch);

  .cardpanel {
    @include flexcolumn(100%, 0);
    position: relative;
    overflow: hidden;

    &.photo {
      height: 100%;
      width: 100%;

      img {
        display: block;
        width: 100%;
      }
    }

    &.content {
      display: flex;
      flex-flow: column nowrap;
      @if ($cover) {
        height: 100%;
      }

      > div {
        width: 100%;  // for ie11 (otherwise it doesn't wrap)
      }
    }

    @include breakpoint-up(md) {
      @include flexcolumn(50%, 0);

      &.photo {
        @if ($cover) {
          @include coverfit;
        }
        position: relative;
        height: auto;
      }

      &.content {
        min-height: $minheight;
        justify-content: center;
        align-content: center;
      }
    }
  }

  @content;
}


// Reset default styles
// WARNING:
//   Be careful using this, in case it
//   resets/overrides more than expected.
// ----------------------------------------------
@mixin reset-styles-lists {
  ul,
  ol {
    margin: 0;
  }
  li {
    margin: 0;
    line-height: inherit;
  }
}


// Checkbox show/hide toggle
// ----------------------------------------------
// Used for the following html structure:
//   <input type="checkbox" class="showtoggle" id="some-toggle-ID" />
//   <!-- stuff to be controlled by toggle -->
//   <label for="some-toggle-ID"><span class="more">Show more</span><span class="less">Show less</span></label>
@mixin showtoggle {
  display: none !important;  // hide the checkbox for the toggle

  ~ label {
    display: inline-block;
    color: $color-red;
    text-transform: uppercase;
    font-family: $fonts-sans;
    font-size: 0.7rem;
    font-weight: $weight-semi;
    cursor: pointer;

    span {
      color: inherit;
    }

    .less {
      display: none;
    }

    &:hover {
      color: $color-black;
    }
  }

  &:checked {
    ~ label {
      .more {
        display: none;
      }
      .less {
        display: block;
      }
    }
  }

  @content;
}

@mixin symbolgallerytile {
  a {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 5%;
    background-color: $color-grey-mid2;

    img {
      flex: none;
      display: block;
      max-width: 100%;
      max-height: 100%;
      margin: auto;
      width: auto;
      height: auto;
    }
  }
}

@mixin videoicon {
  @include showglyph('play-circle2') {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $color-red;
    color: $color-white;
  }

  &::after {
    @include alignedsquareblock(2rem);
    font-size: 1rem;

    @include breakpoint-up(sm) {
      @include alignedsquareblock(3.375rem);
      font-size: 1.7rem;
    }
  }
}

@mixin vertspace {
  margin-bottom: 1rem;

  @include breakpoint-up(md) {
    margin-bottom: 2rem;
  }
}

@mixin zebra($even: true) {
  @if ($even) {
    > div:nth-child(even),
    > li:nth-child(even),
    > tr:nth-child(even),
    > tbody > tr:nth-child(even) {
      background-color: $color-grey-lt;
    }
  }
  @else {
    > div:nth-child(odd),
    > li:nth-child(odd),
    > tr:nth-child(odd),
    > tbody > tr:nth-child(odd) {
      background-color: $color-grey-lt;
    }
  }
}

// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
// ::::: flex grid mixins  adapted from zurb foundation
// :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

@mixin flexrow($behavior: auto, $justify: space-between, $align: stretch, $gutterwidth: $gutter) {
  display: flex;
  flex-flow: row wrap;
  justify-content: $justify;
  align-items: $align;

  @if index($behavior, nest) != null {
    margin-left: (-1 * $gutterwidth / 4);
    margin-right: (-1 * $gutterwidth / 4);

    @include breakpoint-up(xl) {
      margin-left: (-1 * $gutterwidth / 2);
      margin-right: (-1 * $gutterwidth / 2);
    }
  }
  @else if index($behavior, collapse) != null {
    margin-left: 0;
    margin-right: 0;
  }
  @else {
    margin-left: auto;
    margin-right: auto;
  }

  @content;
}

@mixin flexcolumn($width: null, $gutterwidth: $gutter) {
  @if $width != null {
    flex: 0 0 $width;
    @if $width != auto {
      max-width: $width;  // needed for IE
    }
  }
  @else {
    flex: 1 1 0;
  }

  @if $gutterwidth > 0 {
    padding-left: ($gutterwidth / 4);
    padding-right: ($gutterwidth / 4);

    @include breakpoint-up(xl) {
      padding-left: ($gutterwidth / 2);
      padding-right: ($gutterwidth / 2);
    }
  }

  @content;
}
